<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                 Exam pricing
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Exam pricing
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <div class="row">
                    <v-switch
                        v-model="price_edit" @change="changeEditProductPrice"
                        label="Edit prices"
                        color="indigo"
                        value="0"
                        hide-details
                    ></v-switch>
                    <v-btn @click="updateProductPrice" class="ml-4 btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;" v-if="price_edit">
                    Save prices
                  </v-btn>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Search by name or syllabus code"
                      v-model="search.name"
                      outlined
                      dense
                      v-on:keyup.enter="searchProducts()"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type!='score'">
                  <v-select
                      :items="scores"
                      v-on:keyup.enter="searchProducts"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <v-select
                      :items="search_types"
                      v-model="search.type"
                      label="Product type"
                      item-text="name"
                      item-value="value"
                      clearable
                      v-on:keyup.enter="searchProducts()"
                      @input="search.type = $event !== null ? $event : ''"
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-select
                      :items="search_categories"
                      v-model="search.category_id"
                      label="Category"
                      item-text="name"
                      item-value="id"
                      clearable
                      @change="showInstrument"
                      v-on:keyup.enter="searchProducts()"
                      @input="search.category_id = $event !== null ? $event : ''"
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="search_instruments"
                      v-model="search.instrument_id"
                      label="Instrument family"
                      item-text="name"
                      item-value="id"
                      @change="showSyllabus()"
                      outlined
                      clearable
                      :loading="loading_instrument"
                      v-on:keyup.enter="searchProducts()"
                      @input="search.instrument_id = $event !== null ? $event : ''"
                      dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="search_syllabuses"
                      v-model="search.syllabus_id"
                      label="Syllabus"
                      item-text="name"
                      item-value="id"
                      outlined
                      clearable
                      :loading="loading_syllabus"
                      v-on:keyup.enter="searchProducts()"
                      @input="search.syllabus_id = $event !== null ? $event : ''"
                      dense
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      label="Grade"
                      v-model="search.grade_id"
                      :items="grades"
                      outlined clearable
                      item-text="name"
                      item-value="id"
                      dense   @input="search.grade_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchProducts()"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" :md="currentUser.access_type == 'score'  ? 6 : 3">
                  <v-btn
                      @click="searchProducts()"
                      class="btn btn-primary w-35 float-right"
                      :loading="loading"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">	Name</th>
                  <th class="pr-3 ">Price</th>
                  <th class="px-3">	Product type</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="price_edit">
                  <tr v-for="(item,index) in products" :key="index">
                    <td class="px-2">
                      <a class="">
                        {{ item.name }}
                      </a>
                    </td>
                    <td>
                        <v-text-field placeholder="Price" outlined dense v-model="item.score_price"></v-text-field>
                    </td>
                    <td class="px-2">
                      <span class="badge badge-primary text-white font-size-xs">
                        {{ item.type_text }}
                      </span>
                    </td>
                  </tr>
                  <tr v-if="products.length == 0">
                    <td colspan="8" class="text-center">
                      No items added
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item,index) in products" :key="index">
                    <td class="px-2">
                      <a @click.prevent="openQuickView(item)" class="">
                        {{ item.name }}
                      </a>
                    </td>
                    <td>
                      ${{item.score_price}}
                    </td>
                    <td class="px-2">
                      <span class="badge badge-primary text-white font-size-xs">
                       {{ item.type_text }}
                      </span>
                    </td>
                  </tr>
                  <tr v-if="products.length == 0">
                    <td colspan="8" class="text-center">
                      No items added
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="products.length > 0"
                  class="pull-right mt-7"
                  @input="getProducts"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>

  </v-app>
</template>

<script>
import ProductPriceService from "@/services/product/product-price/ProductPriceService";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import ProductService from "@/services/product/ProductService";
import ScoreService from "@/services/score/score/ScoreService";
import GradeService from "@/services/product/grade/GradeService";
const grade = new GradeService();
const score=new ScoreService;
const category = new CategoryService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const product = new ProductService();
const productPrice = new ProductPriceService();

export default{

  data(){
    return{
      productPrices: [],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      products: [],
      search_categories:[],
      search_instruments:[],
      search_syllabuses:[],
      quickViewDialog:false,
      loading_instrument: false,
      loading_syllabus: false,
      search:{
        name: '',
        status: '',
        type: '',
        instrument_id: null,
        category_id: null,
        syllabus_id: null,
        on_special: null,
        score_id:'',
        grade_id:'',
      },
      on_special_list: [
        {
          name: 'Yes',
          value: 1
        },
        {
          name: 'No',
          value: 0
        },
      ],
      grades:[],
      search_types: [
        {name: 'Online Theory Exam', value: 'online_exam'},
        {name: 'Online Theory Course', value: 'online_course'},
        {name: 'Practical Exam', value: 'practical_exam'},
      ],
      productDetail:{},
      price_edit:false,
      scores:[],
      currentUser:{},
      scoreDetail:{},
    }
  },
  methods:{
    searchProducts(){
      this.getProducts();
    },
    changeEditProductPrice(){
      this.getProducts();
    },
    getProducts(){
      this.loading = true;
      product
          .dedicatedPrice(this.search, this.page)
          .then(response => {
            this.products=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.findScoreDetail();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },



    listPrice(product){
      this.$router.push(
          {
            name: 'product-price',
            params: {productId: product.id}
          }
      )
    },


    openQuickView(item){
      this.getPriceByProductId(item.id);
      this.quickViewDialog=true;
      this.productDetail=item;
    },


    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    updateProductPrice(){


      let message='';
      if(this.currentUser.access_type=='score'){
         message='Just confirming that you want to update the price/s for '+ this.currentUser.score.name+' SCORE.';
      }else{
        if(this.search.score_id) {
          message = 'Just confirming that you want to update the price/s for '+this.scoreDetail.name+' SCORE.';
        }else{
          message = 'Just confirming that you want to update the price/s for Federal.';
        }
      }

      this.$confirm({
        message: message,
        button: {
          no: "Cancel",
          yes: "Confirm",
        },
        callback: (confirm) => {
          if (confirm) {
            let scoreId=null;
              if(this.currentUser.access_type=='score'){
                scoreId=this.currentUser.score_id;
               }else{
                  scoreId=this.search.score_id;
              }
                 product
                .updateProductPrice({'score_id':scoreId,'products':this.products})
                .then(response => {
                    this.getProducts();
                    this.price_edit=false;
                })
            }
          }
        });

    },
    findScoreDetail(){
      if(this.search.score_id){
        score
            .show(this.search.score_id)
            .then(response => {

              this.scoreDetail = response.data.score;
            })
             .catch((err) => {

            });
      }
    },
    getAllCategories() {
      category
          .all()
          .then(response => {
            this.search_categories = response.data.categories;
          })
          .catch((err) => {
          });
    },
    getAllInstruments() {
      instrument
          .all()
          .then(response => {
            this.search_instruments = response.data.instruments;
          })
          .catch((err) => {
          });
    },
    getAllSyllabuses() {
      syllabus
          .all()
          .then(response => {
            this.search_syllabuses = response.data.syllabuses;
          })
          .catch((err) => {
          });
    },
    getAllGrades() {
      grade
          .all()
          .then((response) => {
            this.grades = response.data.grades;
          })
          .catch((err) => {});
    },
  },
  mounted(){
    this.getProducts();
    this.getAllScore();
    this.getCurrentUser();
    this.getAllCategories();
    this.getAllInstruments();
    this.getAllSyllabuses();
    this.getAllGrades();
  }
}
</script>
